// i18next-extract-mark-ns-start bizum-empresas

import { ContactSalesButton } from "components/ContactSalesButton";
import { Content } from "components/Content";
import { List } from "components/List";
import "keen-slider/keen-slider.min.css";
import { Section, SectionActions, SectionHeader, SectionImage } from "components/Section";
import { SEO } from "components/SEO";
import { SignUpButton } from "components/SignUpButton";
import { graphql } from "gatsby";
import { Link, Trans, useI18next } from "gatsby-plugin-react-i18next";

import styled from "styled-components";
import { Flex } from "components/Flex";
import React, { useEffect, useRef } from "react";
import { DownloadOnAppleStore } from "components/DownloadOnAppleStore";
import { DownloadOnGooglePlay } from "components/DownloadOnGooglePlay";
import Typed from "typed.js";
import { GradientBox } from "components/landings/GradientBox";
import { MoneiVideoSection } from "components/landings/MoneiVideoSection";
import { PartnersGrid } from "components/landings/PartnersGrid";
//Images
import mediaMarkt from "images/media_markt.svg";
import iberdrola from "images/iberdrola.svg";
import elCorteIngles from "images/corte-ingles.svg";
import qrPaymentMobile_es from "images/qrPaymentMobile_es.svg";
import qrPaymentMobile_en from "images/qrPaymentMobile_en.png";
import scanToPay from "images/scan_to_pay.png";
import moneiQr from "images/monei_qr.svg";
import caixabank from "images/caixa_grey.svg";
import sabadell from "images/sabadell_grey.svg";
import bbva from "images/bbva_grey.svg";
import santander from "images/santander_grey.svg";
import bankinter from "images/bankinter_grey.svg";
import unicaja from "images/unicaja_grey.svg";
import bankia from "images/bankia_grey.svg";
import ing from "images/ing_grey.svg";
import abanca from "images/abanca_grey.svg";
import { AnchorLink } from "components/AnchorLink";
import { StyledDiv, StyledImg } from "components/StyledSx";
import { ScreenSizes } from "../../types/responsive";
import { InternalPageLink } from "components/links/Pages";

const IndexBackground = styled.div`
  position: relative;

  &:before {
    content: '';
    display: block;
    position: absolute;
    left: 50%;
    top: -8.9%;
    height: 1256px;
    width: 1225px;
    background: linear-gradient(353.74deg, #41efd6 -47.57%, #aab4e9 13.85%);
    transform: matrix(2, 0, 0, -1, 1214, -91) rotate(47deg) skew(7deg, 1.6deg);
    @media (max-width: ${ScreenSizes.lg}) {
      content: none;
    }
  }

  &:after {
    display: block;
    content: '';
    position: absolute;
    transform: rotate(34deg) matrix(1, 0, 0, -1, 459, -315);
    left: 50%;
    top: -3%;
    opacity: 1;
    height: 972px;
    width: 1289px;
    background: linear-gradient(353.74deg, #41efd6 -41.57%, #aab4e9 53.85%);
    @media (max-width: ${ScreenSizes.lg}) {
      content: none;
    }
  }

  @media (max-width: 1280px) {
    display: flex;
    flex-direction: column;
  }
  @media (max-width: 1024px) {
    margin-bottom: 48px;
  }
`;

const IndexContent = styled.div`
  padding-top: 100px;
  padding-bottom: 0;
  max-width: 550px;
  min-height: 600px;

  @media (max-width: 1280px) {
    width: 100%;
    max-width: 100%;
    min-height: auto;
  }
`;

const Logo = styled(StyledImg)`
  height: 48px;
  margin-top: 0;
  margin-bottom: 0;
`;

const DynamicSpan = styled.span`
  display: inline;
  color: #5e80d2;
`;

const Background = styled(StyledDiv)`
  position: relative;

  @media (max-width: 768px) {
    padding-top: 0;
  }

  &:before {
    display: block;
    content: '';
    height: 45%;
    position: absolute;
    transform: skew(0, -15deg);
    top: 73%;
    right: 0;
    left: 0;
    bottom: 0;
    background-image: linear-gradient(40deg, #12e0c2 0%, #aab4e9 90%);

    @media (max-width: ${ScreenSizes.md}) {
      display: none;
    }
  }
`;

const QrPaymentMobile = styled.img`
  flex: 1;
  max-width: 50%;
  position: relative;
  @media (max-width: 991px) {
    display: none;
  }
`;

const MaskedImage = styled.img`
  position: absolute;
  right: 35px;
  bottom: -40px;
  margin-bottom: 0;
  transform: rotate(31deg);
  z-index: 0;
  @media (max-width: ${ScreenSizes.lg}) {
    width: 250px;
  }
  @media (max-width: ${ScreenSizes.md}) {
    width: 210px;
  }
  @media (max-width: ${ScreenSizes.sm}) {
    display: none;
  }
`;

const SectionDescription = styled.p`
  font-weight: 500;
  color: #fafafa;
  font-size: 16px;
  z-index: 1;

  a {
    color: #ffffff;
    text-decoration: underline;
  }
`;

const BizumEmpresas: React.FC = () => {
  const {t, language} = useI18next();
  const typeTarget = useRef<HTMLElement>(null);

  const businessList = [
    t('shops'),
    t('restaurants'),
    t('hotels'),
    t('taxi drivers'),
    t('lawyers'),
    t('dentists'),
    t('podiatrists')
  ];

  useEffect(() => {
    const elementId: any = typeTarget.current !== null ? typeTarget.current : '';
    const typed = new Typed(elementId, {
      strings: businessList,
      typeSpeed: 70,
      loop: true
    });

    return () => {
      typed.destroy();
    };
  }, [typeTarget]);
  return (
    <>
      <SEO
        path="bizum-empresas"
        title={t('Bizum for Companies')}
        description={t(
          'Accepting Bizum payments in your physical store or on the go is now possible thanks to MONEI Pay. Get started here ››'
        )}
      />

      <IndexBackground>
        <Content>
          <IndexContent>
            <SectionHeader underline tagName="h1">
              <Trans>Bizum for companies: accept Bizum payments anywhere</Trans>
            </SectionHeader>
            <Trans parent="p">
              MONEI is the first platform that lets you effortlessly accept Bizum payments for
              commerce from any mobile device with the <Link to="/monei-pay/">MONEI Pay</Link> app.
            </Trans>
            <SectionActions>
              <SignUpButton variant="light" style={{marginRight: 15}}>
                <Trans>Open an Account</Trans>
              </SignUpButton>
              <ContactSalesButton />
            </SectionActions>
          </IndexContent>
          <Flex direction="column">
            <StyledDiv sx={{color: '#74858E', marginTop: {xl: '16px', lg: '28px'}}}>
              <Trans>MORE THAN 5,000 STORES ALREADY USE IT</Trans>
            </StyledDiv>
            <Flex
              sx={{
                width: {sm: '100%'},
                flexDirection: {sm: 'column'},
                alignItems: {sm: 'center'},
                marginBottom: {lg: '16px'}
              }}>
              <Logo src={mediaMarkt} />
              <Logo src={iberdrola} style={{margin: '0 30px'}} />
              <Logo src={elCorteIngles} />
            </Flex>
          </Flex>
        </Content>
        <MoneiVideoSection />
      </IndexBackground>

      <Content>
        <Section centered>
          <SectionImage
            src={scanToPay}
            width={321}
            height={600}
            alt="Secure payment gateway"
            title="Secure payment gateway"
            className="hide-on-mobile"
          />
          <div>
            <SectionHeader underline>
              <Trans>
                The first Bizum payment solution
                <br /> for{' '}
              </Trans>{' '}
              <DynamicSpan ref={typeTarget} />
            </SectionHeader>
            <Trans parent="p">
              <InternalPageLink slug="payment-methods/bizum">Bizum payments</InternalPageLink> are gaining popularity among consumers. That’s why we help all business
              types accept Bizum payments through any mobile device with{' '}
              <InternalPageLink slug="monei-pay/qr">digital QR codes</InternalPageLink> or payment
              requests. Whether you have a retail shop,{' '}
              <InternalPageLink slug="monei-pay/qr-code-payment-restaurant">
                restaurant
              </InternalPageLink>
              , or <InternalPageLink slug="monei-pay/retail-POS-system">service-based business</InternalPageLink>, use your web browser to access{' '}
              <AnchorLink href="https://pay.monei.com/">pay.monei.com</AnchorLink> or download the
              Android or iOS <Link to="/monei-pay/">MONEI Pay</Link> app.
            </Trans>
            <List>
              <Trans parent="li">No POS required</Trans>
              <Trans parent="li">Safe and secure contactless payments</Trans>
              <Trans parent="li">Instant payment settlements into your account</Trans>
              <Trans parent="li">50% cheaper than your usual POS</Trans>
            </List>
            <Flex>
              <div style={{marginRight: '16px'}}>
                <DownloadOnAppleStore />
              </div>
              <DownloadOnGooglePlay />
            </Flex>
          </div>
        </Section>
      </Content>

      <Background>
        <Content>
          <Section reverseOnMobile style={{alignItems: 'center'}}>
            <div style={{flex: 1}}>
              <SectionHeader underline>
                <Trans>Forget the dataphone — a QR code is all you need</Trans>
              </SectionHeader>
              <Trans parent="p">
                Create payments in a matter of seconds using MONEI Pay. Generate a digital QR code
                on your mobile device, let your customer scan it to pay, and receive real-time
                payment confirmation.
              </Trans>
              <Trans>
                {' '}
                Get the first solution that lets you integrate Bizum payments in your physical
                stores or on the go.
              </Trans>
              <SectionActions>
                <SignUpButton variant="light">
                  <Trans>Join MONEI</Trans>
                </SignUpButton>
              </SectionActions>
            </div>
            <QrPaymentMobile src={language === 'es' ? qrPaymentMobile_es : qrPaymentMobile_en} />
          </Section>
        </Content>
      </Background>

      <Content>
        <Section style={{alignItems: 'center'}} reverseOnMobile columnMd>
          <PartnersGrid
            partnersList={[
              caixabank,
              sabadell,
              bbva,
              santander,
              bankinter,
              unicaja,
              bankia,
              ing,
              abanca
            ]}
          />
          <div>
            <SectionHeader underline>
              <Trans>Compatible with many Spanish banks</Trans>
            </SectionHeader>
            <Trans parent="p">
              Bizum payments through MONEI Pay are compatible with most Spanish banks and setup is
              quick and easy.
            </Trans>
            <Trans parent="p">
              Sign up for MONEI, and in less than 24 hours, you’ll be able to accept Bizum payments
              anywhere and from any mobile device with digital QR codes and payment requests.
            </Trans>
          </div>
        </Section>
      </Content>

      <Content>
        <GradientBox>
          <SectionHeader tagName="h3" underline light>
            <Trans>Start accepting Bizum payments in-store or on the go</Trans>
          </SectionHeader>
          <SectionDescription>
            <Trans parent="p">
              Make payments easier for customers and for your business. Open a MONEI account today
              to start accepting Bizum payments in-store or from virtually anywhere. All you need is
              a mobile device and MONEI Pay.
            </Trans>
          </SectionDescription>
          <SectionActions>
            <SignUpButton variant="dark">
              {' '}
              <Trans>Open an Account</Trans>
            </SignUpButton>
          </SectionActions>
          <MaskedImage src={moneiQr} width={300} />
        </GradientBox>
      </Content>
    </>
  );
};

export default BizumEmpresas;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(
      filter: {ns: {in: ["common", "bizum-empresas"]}, language: {eq: $language}}
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
