import videoLeftTopCorner from 'images/video_left_top_corner.svg';
import videoLeftBottomCorner from 'images/video_left_bottom_corner.svg';
import baloon from 'images/baloon.svg';
import React from 'react';
import styled from 'styled-components';
import {useTranslation} from 'gatsby-plugin-react-i18next';

const VideoSection = styled.div`
  position: absolute;
  left: 50%;
  top: 150px;
  z-index: 1;

  @media (max-width: 1280px) {
    position: relative;
    left: auto;
    top: auto;
    margin: 24px auto;
  }
`;

const MoneiPayVideo = styled.iframe`
  position: relative;
  left: 50px;
  width: 580px;
  height: 326px;
  border: 0;
  @media (max-width: 1280px) {
    left: 0;
  }
  @media (max-width: 768px) {
    width: 90vw;
  }
`;

const VideoLeftTopCorner = styled.img`
  position: absolute;
  left: 29px;
  top: -19px;

  @media (max-width: 1280px) {
    left: -19px;
  }
`;

const VideoLeftBottomCorner = styled.img`
  position: absolute;
  left: -9px;
  bottom: -53px;

  @media (max-width: 1280px) {
    left: -55px;
  }
`;

const Baloon = styled.img`
  position: absolute;
  bottom: -75px;
  right: -109px;
  width: 125px;
  @media (max-width: 1280px) {
    right: -65px;
  }
  @media (max-width: 768px) {
    display: none;
  }
`;

export const MoneiVideoSection: React.FC = () => {
  const {t} = useTranslation();
  return (
    <VideoSection>
      <VideoLeftTopCorner src={videoLeftTopCorner} />
      <VideoLeftBottomCorner src={videoLeftBottomCorner} />
      <MoneiPayVideo
        src="https://www.youtube.com/embed/ZVmXRUFWqno"
        title={t('Monei Pay Video')}
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; allowfullscreen; clipboard-write"
      />
      <Baloon src={baloon} alt="MONEI" title="MONEI" />
    </VideoSection>
  );
};
