import React from 'react';
import styled from 'styled-components';
import {ScreenSizes} from '../../../types/responsive';
import chevronRight from 'images/chevron_right.svg';
import chevronLeft from 'images/chevron_left.svg';

const Grid = styled.div`
  display: grid;
  grid-gap: 8px;
  grid-template-columns: repeat(3, minmax(200px, 1fr));

  @media (max-width: ${ScreenSizes.md}) {
    display: flex;
    flex-wrap: nowrap;
    overflow: scroll;
    grid-template-columns: unset;
    padding: 24px 48px;
    width: 100vw;
    position: relative;
  }
`;

const Arrow = styled.img`
  width: 32px;
  height: 32px;
  position: absolute;
  top: calc(50% - 12px);
  margin: 0;
  padding: 0;
  z-index: 1;
  opacity: 0;

  &.visible {
    opacity: 1;
  }

  &.left {
    left: 16px;
  }
  &.right {
    right: 16px;
  }

  @media (min-width: ${ScreenSizes.md}) {
    display: none;
  }
`;

const PartnerCard = styled.img`
  box-shadow: 0 1.4426548480987549px 2.7479140758514404px 0 rgba(0, 0, 0, 0.0124),
    0 3.6485824584960938px 6.949680805206299px 0 rgba(0, 0, 0, 0.0177),
    0 7.442766189575195px 14.176697731018066px 0 rgba(0, 0, 0, 0.0223),
    0 15.33069133758545px 29.201316833496094px 0 rgba(0, 0, 0, 0.0276),
    0 42px 80px 0 rgba(0, 0, 0, 0.04);
  width: 200px;
  height: 140px;
  padding: 0 50px;
  background: #fff;
  border-radius: 4px;
  margin: 0;
`;

interface Props {
  partnersList: string[];
}

export const PartnersGrid: React.FC<Props> = ({partnersList}) => {
  const [isVisibleLeft, setIsVisibleLeft] = React.useState(false);
  const [isVisibleRight, setIsVisibleRight] = React.useState(true);

  const scrollCard = (direction: 'left' | 'right') => {
    const grid = document.getElementById('partnersGrid');
    if (!grid) return;
    grid.scrollBy({left: direction === 'left' ? 200 : -200, top: 0, behavior: 'smooth'});
  };

  const handleScroll = () => {
    const grid = document.getElementById('partnersGrid');

    if (!grid) return;
    setIsVisibleLeft(grid.scrollLeft > 200);
    setIsVisibleRight(grid.scrollLeft < (partnersList.length - 2) * 200);
  };

  React.useEffect(() => {
    const grid = document.getElementById('partnersGrid');

    if (!grid) return;
    grid.addEventListener('scroll', handleScroll);
    return () => {
      grid.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div style={{position: 'relative'}}>
      <Grid id="partnersGrid">
        {partnersList.map((partner) => (
          <PartnerCard src={partner} />
        ))}
      </Grid>
      <Arrow
        src={chevronLeft}
        className={`left ${isVisibleLeft ? 'visible' : ''}`}
        onClick={() => scrollCard('right')}
      />
      <Arrow
        src={chevronRight}
        className={`right ${isVisibleRight ? 'visible' : ''}`}
        onClick={() => scrollCard('left')}
      />
    </div>
  );
};
